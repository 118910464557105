@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #181818 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.side_drawer ::-webkit-scrollbar {
  width: 0px;
}

.side_drawer ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #161718;
  border-radius: 10px;
  background-color: transparent;
}

.side_drawer ::-webkit-scrollbar-thumb {
  background: #161718;
  border-radius: 10px;
}

.side_drawer ::-webkit-scrollbar-thumb:hover {
  background: #6c562e;
}

/* .lotteryScroll {
  overflow-x: scroll;
}
.lotteryScroll::-webkit-scrollbar {
  height: 7px;
}
.lotteryScroll::-webkit-scrollbar-track {
  background: #555b71;
  border-radius: 50px;
}
.lotteryScroll::-webkit-scrollbar-thumb {
  background: linear-gradient(180.7deg, #EAB10B 41.33%, #F33E5C 147.05%);
  box-shadow: inset 0px 1px 3px rgba(255, 255, 255, 0.5);
  border-radius: 50px;
} */

.lotteryScroll {
  overflow-x: scroll; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent text wrapping */
  display: flex; /* Use flexbox to align children */
}

.lotteryScroll::-webkit-scrollbar {
  height: 7px;
}

.lotteryScroll::-webkit-scrollbar-track {
  background: #555b71;
  border-radius: 50px;
}

.lotteryScroll::-webkit-scrollbar-thumb {
  background: linear-gradient(180.7deg, #eab10b 41.33%, #f33e5c 147.05%);
  box-shadow: inset 0px 1px 3px rgba(255, 255, 255, 0.5);
  border-radius: 50px;
}

.loading-box {
  position: relative;
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f0f8ff;
  border-radius: 12px;
  /* box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05); */
}

.loading-title {
  font-size: 2em;
  transform: translateY(-12px);
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 300;
}

.loading-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 205px;
  height: 205px;
  background: conic-gradient(
    from 0deg at 50% 50%,
    rgba(234, 177, 11, 1) 0%,
    rgba(243, 62, 92, 1) 0%,
    #101012 0%
  );
  border-radius: 100px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.75),
    -4px -4px 16px rgba(255, 255, 255, 0.2);
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-count {
  content: " ";
  display: flex;
  justify-content: center;
  align-items: center;
  top: 3px;
  left: 3px;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background: #161719;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.75) inset;
  animation: spin 1s infinite linear reverse;
}

.loading-count #loadingNumber {
  font-size: 4em;
}
